/* spell-checker: disable */

export const environment = {
	firebase: {
		apiKey: "AIzaSyAfp4lerGrkPbSXsYsfwWYgyVSeYaVNC_8",
		authDomain: "storebase-ab4fb.firebaseapp.com",
		projectId: "storebase-ab4fb",
		storageBucket: "storebase-ab4fb.appspot.com",
		messagingSenderId: "818029579253",
		appId: "1:818029579253:web:03687369e8eed97185aebc",
		measurementId: "G-FY4YRTC656",
	},
	production: true,
	useEmulators: false,

	gcpRegion: "asia-south1",

	mainServer: {
		baseUrl: "https://main-server-27u7g726zq-el.a.run.app",
	},

	debounceTime: 130,
};
